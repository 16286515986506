.app {
  /* text-align: center;
  display: flex;
  flex-direction: row;
  justify-content:center ;
  width: auto; */

  text-align: center;
  display: flex;
  flex-direction: column; /* Changed to column for better stacking on small screens */
  align-items: center;
  width: 100%; /* Ensure it spans full width */
  padding: 10px; /* Add padding for mobile screens */
}
#root {
  max-width: fit-content;
  margin: auto;
}
.box {
  background: #fff;
  border: 3px solid #fff0f179;
  border-radius: 3px;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 44px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 44px;
  margin: 3px;
  font: 800 24px;
  -webkit-text-fill-color: white;
  -webkit-text-stroke: 1px;
}
.grid-container {
  /* display: grid;
  width: fit-content;
  
   justify-content: center;
  align-content: center;
   place-self: center;
   padding: 10px;
   border: 2px solid black;
   border-radius: 3px;
      grid-column: auto / span 4;
   grid-column-start: 5;
  gap: 10px;  Space between items */

  display: grid;
  width: 100%;
  max-width: 1000px; /* Limit max-width for large screens */
  margin: 0 auto; /* Center align horizontally */
  padding: 10px;
  border: 2px solid black;
  border-radius: 3px;
  grid-template-columns: repeat(4, 1fr); /* 4 columns by default */
  gap: 10px; /* Space between items */
}

.grid-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Adjust column count as needed */
  /*gap: 10px;  Space between items */
}

.grid-item {
  /*padding: 10px;*/
  border: 1px solid #fff0f179;
  text-align: center;
}

.rubik-nums {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}
.headerDiv {
  width: 100%;
  flex-direction: column;
}

.rubik-micro {
  text-align: center;
  font-family: "Rubik", sans-serif;
  color: #ddd;
  font-size: 7.5vw;
  /* font-optical-sizing: auto; */
  font-weight: 800;
  font-style: normal;
  -webkit-text-fill-color: white;
  -webkit-text-stroke: 2px;
  text-shadow: 0px 2px 2px #000;
  margin-bottom: 5px;
}

.micro-red {
  -webkit-text-fill-color: #b71234;
}
.micro-blue {
  -webkit-text-fill-color: #0046ad;
}
.micro-green {
  -webkit-text-fill-color: #009b48;
}
.micro-yellow {
  -webkit-text-fill-color: #ffd500;
}
@media screen and (max-width: 700px) {
  .rubik-micro {
    font-size: 60px; /* Adjust to a suitable size for small screens */
  }
}

.instructionContainer {
  /* float: right;
grid-area: right;
justify-items: end; */
  max-width: 292px;
  grid-column: auto / span 4;
  grid-column-start: 9;
  margin: 10px;
  align-self: center;
}

.instructionsBox {
  border: solid 2px rgb(0, 0, 0);
  padding: 10px;
  border-radius: 15px;

  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}
.instructionsGrid {
  display: block;
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
.gameContainer {
  text-align: center;

  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-areas: "left middle right";
  justify-content: center;
  align-content: center;

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr auto;
  justify-items: center;

  /* display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; */
}

.gameBox {
  display: inline-block;
  vertical-align: middle;

  grid-area: middle;
  margin: 0px 80px;
  grid-column: auto / span 4;
  grid-column-start: 5;

  justify-content: start;
}

.timerDiv {
  margin: 12px;
  height: 44px;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 700px) {
  .gameBox {
    grid-column: col-start / span 12;
  }
  .rubikMicro {
    font-size: 20vw;
  }
  .gameContainer {
    display: flex;
    flex-direction: column;
  }
  .gameBox {
    flex-direction: column;
  }
  .instructionContainer {
    display: block;
  }
}
@media screen and (max-width: 700px) {
  .rubikMicro {
    font-size: 50px;
  }
}
.winnerDiv {
  height: 44px;
}
.winnerStats{
  text-align: center;
  font-size: 12px;
  margin-bottom: 9px;
  margin-top: 4px;
  font-family: "Rubik", sans-serif;
}
.winner {
  
  position: absolute;
  text-align: center;
  font-family: "Rubik", sans-serif;
  color: #ddd;
  font-size: 50px;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  -webkit-text-fill-color: white;
  -webkit-text-stroke: 2px;
  text-shadow: 0px 2px 5px #000;
  margin: 5px;
  max-height: 44px;
  animation: winnerUp 4s forwards; 
  z-index: 1;
  margin-top: -10px;
  align-self: center;
  text-align: center;
 
}
@keyframes winnerUp{
  0%{
    transform: translateY(200px);
  }
  50%{
    transform: scale(1.3);
  }
  100%{
    transform: translateY(0px);
  }
}
.winner-red {
  max-height: 44px;
  animation: changeRed .75s linear infinite;
}
.winner-blue {
  animation: changeBlue .75s  linear infinite;
}
.winner-green {
  animation: changeGreen .75s  linear infinite;
}
.winner-yellow {
  animation: changeYellow .75s linear infinite;
}
@keyframes changeRed {
  0% {
    -webkit-text-fill-color: #b71234;
  }
  25% {
    -webkit-text-fill-color: #0046ad;
  }
  50% {
    -webkit-text-fill-color: #009b48;
  }
  75% {
    -webkit-text-fill-color: #ffd500;
  }
  100% {
    -webkit-text-fill-color: #b71234;
  }
}
@keyframes changeBlue {
  
  0% {
    -webkit-text-fill-color: #0046ad;
  }
  25% {
    -webkit-text-fill-color: #009b48;
  }
  50% {
    -webkit-text-fill-color: #ffd500;
  }
  75% {
    -webkit-text-fill-color: #b71234;
  }
   
  100% {
    -webkit-text-fill-color: #0046ad;
  }
}
@keyframes changeGreen {
  0% {
    -webkit-text-fill-color: #009b48;
  }
  25% {
    -webkit-text-fill-color: #ffd500;
  }
  50% {
    -webkit-text-fill-color: #b71234;
  }
  75% {
    -webkit-text-fill-color: #0046ad;
  }
   100% {
    -webkit-text-fill-color: #009b48;
  }
}
@keyframes changeYellow {
  
  0% {
    -webkit-text-fill-color: #ffd500;
  }
  25% {
    -webkit-text-fill-color: #b71234;
  }
  50% {
    -webkit-text-fill-color: #0046ad;
  }
  75% {
    -webkit-text-fill-color: #009b48;
  }
  100% {
    -webkit-text-fill-color: #ffd500;
  }
}
.winner-red {
  -webkit-text-fill-color: #b71234;
}
.winner-blue {
  -webkit-text-fill-color: #0046ad;
}
.winner-green {
  -webkit-text-fill-color: #009b48;
}
.winner-yellow {
  -webkit-text-fill-color: #ffd500;
}
.winnerStats {
  font-family: "Rubik", sans-serif;
}
