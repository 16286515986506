
.mobileControlsContainer{
 display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 222px;
  margin-top: 25px;
  
    display: inline-block ;
    vertical-align: middle;
}



.upDiv{
    display: inline-block;
}
.buttonSpace{
    margin-left: 7.5em
}

.btn {
       
    margin: 2px 20px;
    color: #fff;
    border-radius: 10px;
    padding: 8px 12px 12px 12px;
      font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
 border: solid 2px #fff;
box-shadow: 0px 0px 3px #000;
 font-size: 18px;
 font-optical-sizing: auto;
}
.upButton{
    background-color: #b71234;
    margin-bottom: 10px;
    
}
.downButton{
    background-color: #ffd500;
    margin-top: 10px;
}
.rightButton{
    /* padding-top: 2px; */
    background-color: #009b48;
    margin-left: 30px;
}
.leftButton{
    /* padding-top: 2px; */
    background-color: #0046ad;
}
.spaceButton{
    background-color: #b71234;
    padding-top: 12px;
}
